import React from 'react';
import styled from 'styled-components';
import {Select, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {formatDateStr} from '../../Utils/TimeUtil';

function MeetingSection(props) {
  const [user] = useOutlet('user');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [selectedYear, setSelectedYear] = React.useState(
    `${new Date().getFullYear()}`,
  );
  const [meetings, setMeetings] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      try {
        let meetings_resp = await JStorage.fetchDocuments(
          'Meeting',
          {
            EFF_DATE: {$regex: selectedYear},
            IS_SHOW: 'Y',
          },
          ['-EFF_DATE'],
        );

        setMeetings(meetings_resp.results);
      } catch (err) {
        console.warn(err);
      }
    }

    fetchData();
  }, [selectedYear]);

  return (
    <Wrapper mobile={mobile}>
      <div className="container">
        <h2 className="title">公會會議紀錄</h2>
        <div className="filter">
          <Select
            style={{width: 100}}
            value={selectedYear}
            onChange={(value) => setSelectedYear(value)}>
            <Select.Option value="2022">2022</Select.Option>
            <Select.Option value="2023">2023</Select.Option>
            <Select.Option value="2024">2024</Select.Option>
          </Select>
        </div>
        <div className="content">
          {meetings?.map((record, index) => (
            <div
              className="item"
              key={`meeting-${index} ${user && user.sub ? 'cursor' : ''}`}
              onClick={(e) => {
                AppActions.navigate(`/public-service/meeting/?id=${record.id}`);
              }}>
              <div className="date">
                {formatDateStr(new Date(record.EFF_DATE))}
              </div>
              <div className="title">{record.MTNG_NAME}</div>
              <div className="appendix">
                {record.files?.map((file, idx) => (
                  <div className="file" key={`file-${idx}`}>
                    <div className="display">{file.title}</div>
                    <Button
                      type="link"
                      target="_blank"
                      className="button"
                      href={file.url}
                      onClick={(e) => e.stopPropagation()}>
                      檔案
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="item fake" />
          <div className="item fake" />
          <div className="item fake" />
          <div className="item fake" />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px 45px 40px;
  width: 100%;

  & > .container {
    margin: 0 auto;
    max-width: 940px;
    width: 100%;

    & > .title {
      margin-bottom: 0px;
      text-align: center;
    }

    & > .filter {
      display: flex;
      justify-content: flex-end;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
      padding-bottom: 20px;
      border-bottom: 6px solid var(--primaryColor);
    }

    & > .content {
      display: flex;
      justify-content: ${(props) =>
        props.mobile ? 'space-around' : 'space-between'};
      align-items: center;
      flex-wrap: wrap;

      & > .item {
        margin: 20px;
        max-width: 190px;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 6px solid #c8c8c8;

        @media screen and (max-width: 500px) {
          max-width: 100%;
        }

        & > .date {
          margin-bottom: 15px;
          font-weight: 500;
          font-size: 1rem;
        }

        & > .title {
          margin-bottom: 15px;
          font-weight: 500;
          font-size: 1rem;
        }

        & > .appendix {
          & > .file {
            & > .display {
              margin-bottom: 10px;
              color: var(--secondColor);
            }

            & > .button {
              border: 1px solid var(--secondColor);
              border-radius: 15px;
              padding: 4px 8px;
              color: var(--secondColor);
            }
          }
        }

        &.fake {
          border-bottom: none;
        }

        .& .pointer {
          cursor: pointer;
        }
      }
    }
  }
`;

export default MeetingSection;
