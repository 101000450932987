import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import withPageEntry from '../../withPageEntry';
import {prependTwoZero} from '../../Utils/TimeUtil';
import MainSection from './MainSection';
import ProductsSection from './ProductsSection';
import FindSection from './FindSection';
import MeetingSection from './MeetingSection';

const todayMS = new Date(
  `${new Date().getFullYear()}-${prependTwoZero(
    new Date().getMonth() + 1,
  )}-${prependTwoZero(new Date().getDate())}`,
).getTime();

const getQueryTime = () => {
  const thisMonth = {
    year: `${new Date().getFullYear()}`,
    month: `${prependTwoZero(new Date().getMonth() + 1)}`,
  };

  let lastMonth = {
    year: `${new Date().getFullYear()}`,
    month: `${prependTwoZero(new Date().getMonth())}`,
  };
  let nextMonth = {
    year: `${new Date().getFullYear()}`,
    month: `${prependTwoZero(new Date().getMonth() + 2)}`,
  };

  if (new Date().getMonth() === 0) {
    lastMonth.year = `${new Date().getFullYear() - 1}`;
    lastMonth.month = `12`;
  } else if (new Date().getMonth() === 11) {
    nextMonth.year = `${new Date().getFullYear() + 1}`;
    nextMonth.month = `01`;
  }

  return [
    {$regex: `${thisMonth.year}-${thisMonth.month}`},
    {$regex: `${lastMonth.year}-${lastMonth.month}`},
    {$regex: `${nextMonth.year}-${nextMonth.month}`},
  ];
};

function Member(props) {
  const [user] = useOutlet('user');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  const [courses, setCourses] = React.useState(null);
  const [activities, setActivities] = React.useState(null);
  const [jobs, setJobs] = React.useState(null);
  const [rooms, setRooms] = React.useState(null);

  const isRenderMeetingSection =
    user &&
    user.data &&
    user.data.user_type === 'member' &&
    user.data.state === 1;

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const productQueries = {
          public: true,
          archieved: {$ne: true},
          type: {$ne: 'group_item'},
          $or: [
            {
              onshelf_start_date: {
                $lte: new Date().toISOString().split('T')[0],
              },
              onshelf_end_date: {
                $gte: new Date().toISOString().split('T')[0],
              },
            },
            {
              onshelf_start_date: {$exists: false},
              onshelf_end_date: {$exists: false},
            },
            {
              onshelf_start_date: '',
              onshelf_end_date: '',
            },
            {
              onshelf_start_date: null,
              onshelf_end_date: null,
            },
          ],
        };

        const courses_resp = await JStorage.fetchDocuments(
          'product',
          {
            labels: {$in: [{$regex: '課程'}]},
            ...productQueries,
          },
          ['-session.date'],
          {offest: 0, limit: 8},
        );

        const activities_resp = await JStorage.fetchDocuments(
          'product',
          {labels: {$in: [{$regex: '活動'}]}, ...productQueries},
          ['-session.date'],
          {offset: 0, limit: 8},
        );

        const jobs_resp = await JStorage.fetchDocuments(
          'review_request',
          {type: 'job', status: 'posted'},
          ['-created'],
          {offset: 0, limit: 8},
        );

        const job_revoke_records = jobs_resp.results.filter((r) => {
          const startTimeMS = new Date(r.data.Start_Time).getTime();
          const endTimeMs = startTimeMS + 30 * 24 * 60 * 60 * 1000;
          return endTimeMs < todayMS;
        });

        const rooms_resp = await JStorage.fetchDocuments(
          'review_request',
          {type: 'room', status: 'posted'},
          ['-created'],
          {offset: 0, limit: 8},
        );

        const room_revoke_records = rooms_resp.results.filter((r) => {
          const startTimeMS = new Date(r.data.Start_Time).getTime();
          const endTimeMs = startTimeMS + 30 * 24 * 60 * 60 * 1000;
          return endTimeMs < todayMS;
        });

        setCourses(courses_resp.results);
        setActivities(activities_resp.results);
        setJobs(
          jobs_resp.results
            .filter((r) => {
              const revokeIdx = job_revoke_records.findIndex(
                (rr) => rr.id === r.id,
              );
              return revokeIdx === -1;
            })
            .map((r) => ({...r, ...r.data})),
        );
        setRooms(
          rooms_resp.results
            .filter((r) => {
              const revokeIdx = room_revoke_records.findIndex(
                (rr) => rr.id === r.id,
              );
              return revokeIdx === -1;
            })
            .map((r) => ({...r, ...r.data})),
        );

        AppActions.setLoading(false);
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <Wrapper mobile={mobile}>
      <MainSection />
      <ProductsSection courses={courses} activities={activities} />
      <FindSection jobs={jobs} rooms={rooms} />
      {isRenderMeetingSection && <MeetingSection />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: ${(props) =>
    props.mobile ? `var(--topRwdNavBarHeight)` : `var(--topNavBarHeight)`};
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default withPageEntry(Member);
