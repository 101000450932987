import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {Clock} from '@styled-icons/bootstrap/Clock';
import {Person} from '@styled-icons/bootstrap/Person';
import {Building} from '@styled-icons/bootstrap/Building';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {formatDateStr} from '../../Utils/TimeUtil';

function FindSection(props) {
  const {jobs, rooms} = props;
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <Wrapper>
      <div className="container">
        <div className="tabs-header">
          <div
            className={`tab ${selectedTab === 0 && 'selected'} `}
            onClick={() => setSelectedTab(0)}>
            求才
          </div>
          <div
            className={`tab ${selectedTab === 1 && 'selected'} `}
            onClick={() => setSelectedTab(1)}>
            辦公室
          </div>
        </div>
        <div className="tabs-content">
          {selectedTab === 0 && (
            <>
              <div className="item">
                <div style={{flex: 1.5}}>刊登日期</div>
                <div style={{flex: 1}}>職務</div>
                <div style={{flex: 1.5}}>事務所/公司</div>
                <div style={{flex: 0.8}}>專長</div>
                <div style={{flex: 0.8}}>學歷</div>
              </div>
              {jobs &&
                jobs.map((item, index) => (
                  <div
                    className="item"
                    key={`job-${index}`}
                    onClick={() =>
                      AppActions.navigate(`/find?id=${item.id}&type=job`)
                    }>
                    <div className="date">
                      {formatDateStr(new Date(item.Start_Time))} ~
                      {formatDateStr(
                        new Date(
                          new Date(item.Start_Time).getTime() +
                            30 * 24 * 60 * 60 * 1000,
                        ),
                      )}
                    </div>
                    <div className="name">
                      {Array.isArray(item.openings) &&
                        item.openings.map((opening, index) => (
                          <div key={`opening-${index}`}>{opening.name}</div>
                        ))}
                    </div>
                    <div className="company">{item.name}</div>
                    <div className="condition">
                      {Array.isArray(item.openings) &&
                        item.openings.map((opening) => (
                          <div>{opening.expertise}</div>
                        ))}
                    </div>
                    <div className="condition">
                      {Array.isArray(item.openings) &&
                        item.openings.map((opening) => (
                          <div>{opening.education}</div>
                        ))}
                    </div>
                  </div>
                ))}
            </>
          )}

          {selectedTab === 1 && (
            <>
              <div className="item">
                <div style={{flex: 1.5}}>刊登日期</div>
                <div style={{flex: 1}}>事務所名稱</div>
                <div style={{flex: 1.4}}>地址</div>
                <div style={{flex: 0.5}}>坪數</div>
              </div>
              {rooms &&
                rooms.map((item, index) => (
                  <div
                    className="item"
                    key={`room-${index}`}
                    onClick={() =>
                      AppActions.navigate(`/find?id=${item.id}&type=room`)
                    }>
                    <div className="date">
                      {formatDateStr(new Date(item.Start_Time))} ~
                      {formatDateStr(
                        new Date(
                          new Date(item.Start_Time).getTime() +
                            30 * 24 * 60 * 60 * 1000,
                        ),
                      )}
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="address">{item.address_firm}</div>
                    <div className="note">
                      {item.square_feet && item.square_feet}
                    </div>
                  </div>
                ))}
            </>
          )}

          <div className="item fake" />
          <div className="item fake" />
          <div className="item fake" />
        </div>

        <div className="actions">
          <Button
            type="text"
            className="more"
            onClick={() => {
              if (selectedTab === 0) {
                AppActions.navigate('/finds?type=job');
              } else if (selectedTab === 1) {
                AppActions.navigate('/finds?type=room');
              }
            }}>
            更多{selectedTab === 0 ? '求才' : '辦公室'}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 60px 40px 45px 40px;
  width: 100%;

  & > .container {
    margin: 0 auto;
    max-width: var(--containerMaxWidth900px);
    width: 100%;

    & > .tabs-header {
      display: flex;

      & > .tab {
        flex: 1;
        cursor: pointer;
        border-bottom: 6px solid #969696;
        padding-bottom: 10px;
        text-align: center;
        font-weight: 600;
        font-size: 1.2rem;

        &.selected {
          border-bottom: 6px solid var(--primaryColor);
        }
      }
    }

    & > .tabs-content {
      min-height: 300px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      & > .item {
        cursor: pointer;
        border-bottom: 3px solid #c8c8c8;
        padding: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > .date {
          flex: 1.5;
          margin-right: 10px;
          font-size: 0.95rem;
        }

        & > .name {
          flex: 1;
          margin-right: 5px;
          font-weight: 600;
          font-size: 1.15rem;
          color: #6e2d8c;
        }

        & > .condition {
          flex: 1;
          font-size: 0.9rem;
        }

        & > .company {
          flex: 1.5;
          font-size: 0.9rem;
          color: var(--primaryColor);
        }

        & > .address {
          flex: 1.4;
          font-size: 0.9rem;
          color: var(--primaryColor);
        }

        & > .note {
          flex: 0.5;
          font-size: 0.9rem;
        }

        &.fake {
          border-bottom: none;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
    }

    & > .actions {
      display: flex;
      justify-content: center;

      & > .more {
        font-weight: 600;
      }
    }
  }
`;

export default FindSection;
