import React from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {PlaySquareOutlined, VideoCameraOutlined} from '@ant-design/icons';
import * as AppActions from '../../AppActions';

function MainSection(props) {
  const showLoginModal = useOutletSetter('login-modal');
  const showResetPasswordModal = useOutletSetter('reset-password-modal');
  const [user] = useOutlet('user');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  const isLagacyUser = user?.data?.created
    ? new Date(user.data.created).getTime() < new Date('2023-11-01').getTime()
    : false;
  const isExtraMemberUser = user?.data?.user_type === 'extra_member';
  const isCrossMemberUser = user?.data?.state === 5 || user?.data?.state === 6;

  return (
    <Wrapper mobile={mobile}>
      <div
        className={`sub-container ${
          mobile ? 'justify-center' : 'justify-end'
        }`}>
        <div className="profile-container">
          <div className="content">
            {user ? (
              <div className="bgColor-btn-container">
                <Button
                  className="item-btn"
                  size="small"
                  onClick={() => AppActions.navigate('/profile')}>
                  個人資訊
                </Button>
                <span className="item-divider">|</span>
                <Button
                  className="item-btn"
                  size="small"
                  onClick={() => AppActions.navigate('/profile/fees')}>
                  會費繳納
                </Button>
                <span className="item-divider">|</span>

                <Button
                  className="item-btn"
                  size="small"
                  onClick={() => AppActions.navigate('/profile/orders')}>
                  報名紀錄
                </Button>
                <span className="item-divider">|</span>
                <Button
                  className="item-btn"
                  size="small"
                  onClick={() => showResetPasswordModal({admin: false})}>
                  重設密碼
                </Button>
              </div>
            ) : (
              <div className="bgColor-btn-container">
                <Button
                  className="item-btn"
                  size="small"
                  onClick={() => showLoginModal(true)}>
                  申請入會
                </Button>
                <span className="item-divider">|</span>
                <Button
                  className="item-btn"
                  size="small"
                  onClick={() => showLoginModal(true)}>
                  會員登入
                </Button>
              </div>
            )}
            <h2 className="title">
              {user ? `${user.data?.name || ''}會員，您好！` : `您尚未登入會員`}
            </h2>
            <div className="description">
              會員只要進入台北律師公會網站，登入您的會員帳號及密碼，您可就需繳納之常年會費、欲參加之課程、活動等，進行報名及繳納各項費用。
            </div>
            <div className="links justify-center">
              <Button
                type="link"
                className="link"
                onClick={() => AppActions.navigate('/rights')}>
                會員服務權益
              </Button>
              <span>|</span>
              <Button
                type="link"
                className="link"
                onClick={() => AppActions.navigate('/physical')}>
                健康檢查專案
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`sub-container bgcolor ${
          mobile ? 'justify-center' : 'justify-start'
        }`}>
        <div className="service-container">
          <div className="content">
            <h2 className="title primaryColor">會員服務</h2>
            <div className="divider" />
            <div className="buttons">
              <Button
                type="primary"
                className="action"
                size="small"
                onClick={() =>
                  AppActions.navigate('/welfare?group=tba&category=items')
                }>
                福利搶鮮報
              </Button>

              {user && (
                <>
                  {!isExtraMemberUser && !isCrossMemberUser && (
                    <Button
                      type="primary"
                      className="action"
                      size="small"
                      onClick={() => AppActions.navigate('/member-download')}>
                      會員下載中心
                    </Button>
                  )}
                  {!isExtraMemberUser && !isCrossMemberUser && (
                    <Button
                      type="primary"
                      className="action"
                      size="small"
                      onClick={() => AppActions.navigate('/committees')}>
                      委員會與社團
                    </Button>
                  )}
                  <Button
                    type="primary"
                    className="action"
                    size="small"
                    onClick={() => AppActions.navigate('/joins')}>
                    參與紀錄
                  </Button>
                  {!isExtraMemberUser && (
                    <Button
                      type="primary"
                      className="action"
                      size="small"
                      onClick={() => AppActions.navigate('/surveys')}>
                      問卷填寫
                    </Button>
                  )}
                  {!isExtraMemberUser && !isCrossMemberUser && (
                    <Button
                      type="primary"
                      className="action"
                      size="small"
                      onClick={() => AppActions.navigate('/company-requests')}>
                      事務所變更申請
                    </Button>
                  )}
                  <Button
                    type="primary"
                    className="action"
                    size="small"
                    onClick={() => AppActions.navigate('/job-requests')}>
                    求才刊登申請
                  </Button>
                  <Button
                    type="primary"
                    className="action"
                    size="small"
                    onClick={() => AppActions.navigate('/room-requests')}>
                    辦公室刊登申請
                  </Button>
                  {isLagacyUser && (
                    <Button
                      type="primary"
                      className="action"
                      size="small"
                      onClick={() => AppActions.navigate('/legacy-data')}>
                      112年前資料查詢
                    </Button>
                  )}
                </>
              )}

              {user && (
                <>
                  <div className="divider" />

                  <Button
                    type="primary"
                    ghost
                    className="action bgcolor-white"
                    icon={<PlaySquareOutlined />}
                    onClick={() => AppActions.navigate('/videos')}>
                    北律雲-課程影片
                  </Button>

                  <Button
                    type="primary"
                    ghost
                    className="action bgcolor-white"
                    icon={<VideoCameraOutlined />}
                    onClick={() => AppActions.navigate('/live_streams')}>
                    北律雲-直播串流
                  </Button>

                  {isCrossMemberUser && (
                    <Button
                      type="primary"
                      ghost
                      className="action bgcolor-white"
                      onClick={() =>
                        AppActions.navigate(
                          '/product/?id=6401664a2512ae8cbb2b1af0',
                        )
                      }>
                      跨區會費購買
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? `column` : `row`)};
  align-items: center;

  & > .sub-container {
    flex: 1;
    width: 100%;
    padding: 70px 10px 45px 10px;

    display: flex;
    align-items: end;

    & > .profile-container {
      max-width: 500px;
      width: 100%;
      min-height: 330px;

      & > .content {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
        display: flex;
        flex-direction: column;

        & > .title {
          margin-bottom: 10px;
          text-align: center;
          font-weight: 600;
        }

        & > .description {
          margin-bottom: 5px;
          line-height: 2rem;
          font-size: 1.05rem;
        }

        & > .bgColor-btn-container {
          margin-bottom: 30px;
          width: 100%;
          height: auto;
          padding: 5px;
          background-color: #323232;

          display: flex;
          justify-content: center;
          align-items: center;

          & > .item-btn {
            border: none;
            background-color: transparent;
            color: var(--primaryColor);
            font-size: 16px;
            display: block;
          }

          & .item-divider {
            font-weight: 600;
            font-size: 16px;
            color: var(--primaryColor);
            margin-right: 4px;
            margin-left: 4px;
          }

          @media screen and (max-width: ${375 - 1}px) {
            & .item-btn {
              font-size: 14px;
            }
          }

          @media screen and (max-width: ${330 - 1}px) {
            flex-wrap: wrap;
          }
        }

        & > .links {
          margin-top: 15px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          & > .link {
            font-size: 0.95rem;
          }
        }
      }
    }

    & > .service-container {
      max-width: 500px;
      width: 100%;
      min-height: 330px;

      & > .content {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
        display: flex;
        flex-direction: column;

        & .divider {
          margin: 10px 6px;
          width: 100%;
          border-bottom: 6px solid var(--primaryColor);
        }

        & > .title {
          text-align: center;
          font-weight: 500;
        }

        & > .buttons {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 3px 0px;

          & > .action {
            margin: 8px;
            letter-spacing: 0.1rem;
            font-size: 14px;

            &.first-child {
              margin-left: 0px;
            }
          }

          & > .bgcolor-white {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  & .primaryColor {
    color: var(--primaryColor);
  }

  & .bgcolor {
    background-color: #f2f2f2;
  }

  & .justify-end {
    justify-content: flex-end;
  }

  & .justify-start {
    justify-content: flex-start;
  }

  & .justify-center {
    justify-content: center;
  }
`;

export default MainSection;
